import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox, Select } from '../Form';
import activities from './activities';

const schema = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  educatorName: {
    label: 'Educator Name',
    component: TextInput,
    type: 'text',
    value: '',
  },
  schoolClubName: {
    label: 'School/Club Name',
    component: TextInput,
    type: 'text',
    value: '',
  },
  address: {
    label: 'Address',
    component: TextInput,
    type: 'text',
    value: '',
  },
  cityTown: {
    label: 'City/Town',
    component: TextInput,
    type: 'text',
    value: '',
  },
  stateProvince: {
    label: 'State/Province',
    component: TextInput,
    type: 'text',
    value: '',
  },
  zipcode: {
    label: 'Zipcode',
    component: TextInput,
    type: 'text',
    value: '',
  },
  gradeLevel: {
    label: 'Grade Level of Students',
    component: TextInput,
    type: 'text',
    value: '',
  },
  phone: {
    label: 'Phone',
    component: TextInput,
    type: 'text',
    value: '',
  },
  availability: {
    label:
      'Please let us know the days, times, and time zone that would work best for a visit.',
    component: TextInput,
    type: 'text',
    value: '',
  },
  comments: {
    label: 'Additional Comments',
    component: TextInput,
    type: 'text',
    value: '',
  },
  activity: {
    label: 'Activity Selector',
    component: Select,
    placeholder: 'Select',
    options: activities,
    value: '',
    required: false,
    labelHidden: false,
  },
  privacyPolicyAgreement: {
    label: (
      <>
        By signing-up and clicking this box, you agree to Discovery Education’s{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{' '}
        and to ASME’s{' '}
        <a
          href="https://www.asme.org/privacy-and-security-statement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        . Both entities may contact you as described in their policies.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information about {programName} initiatives in
        accordance with the following{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

export default schema;
