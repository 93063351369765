import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import Image from '../../components/Image';
import bgImage from '../../images/img-home-about-bg.png';
import Subfooter from '../../components/Subfooter/index';
import RequestAVisit from '../../components/RequestAVisit';
import Button from '../../components/Button';
import '../../styles/pages/_educators.scss';
import '../../styles/pages/_about.scss';

const RequestVirtualVisit = () => {
  return (
    <Layout title="Request Virtual Classroom Visit">
      <Hero
        mediaSource="top-header-educator-request@2x.png"
        textOverlay={{
          heading: 'Request a Virtual Classroom Visit',
          pageTitle: 'Educators',
        }}
      />
      <Section>
        {/* Intro */}
        <Container>
          <Section className="virtual-visit">
            <Row>
              <Column size={6}>
                <Image filename="img-educator-visit-intro.jpg" />
              </Column>
              <Column size={6}>
                <h2>Request a Virtual Classroom Visit</h2>
                <p className="visit-heading">
                  Embrace firsthand learning with a 30-minute virtual classroom
                  visit from a real engineer.
                </p>
                <p>
                  Discovery Education and the American Society of Mechanical
                  Engineers (ASME) are excited to connect K–12 classrooms in
                  your school with real-world engineers during a 30-minute
                  Virtual Drop-In. Schedule a classroom visit now, and we’ll
                  reach out within 24-hours to confirm your request.
                </p>
                <RequestAVisit
                  trigger={
                    <Button className="footer__signup-button">
                      Request a Virtual Visit
                    </Button>
                  }
                />
              </Column>
            </Row>
          </Section>
        </Container>
      </Section>

      <Section className="pb-0 virtual-visit__resources">
        {/* Resources for everyone */}
        <Container
          fullWidth
          style={{ backgroundImage: `url("${bgImage}")` }}
          className="virtual-visit__resources-bg-img pb-2 pt-2 pl-2 pr-2"
        >
          <h2 className="pb-1">Resources for Everyone</h2>
          <Row>
            <Column size={4} className="virtual-visit__resources">
              <Image
                filename="img-about-res-think-engineer.jpg"
                className="virtual-visit__resources-img"
              />
              <p className="virtual-visit__resources-heading">
                Learn to Think Like an Engineer
              </p>
              <p className="virtual-visit__resources-body pb-0.5">
                Give students the blueprints to use engineering in their own
                lives. The Engineering Habits of Mind is a powerful visual tool
                for teachers & learners.
              </p>
              <Button to="/engineer">Learn More</Button>
            </Column>
            <Column size={4} className="virtual-visit__resources">
              <Image
                filename="img-about-res-discover-careers.jpg"
                className="virtual-visit__resources-img"
              />
              <p className="virtual-visit__resources-heading">
                Discover Exciting Engineering Careers
              </p>
              <p className="virtual-visit__resources-body pb-0.5">
                See the many faces of engineering with interactive career
                explorations. Dive into the lives of real-world engineers
                working in industries of all kinds.
              </p>
              <Button to="/students">Learn More</Button>
            </Column>
            <Column size={4} className="virtual-visit__resources">
              <Image
                filename="img-about-res-explore-topics.jpg"
                className="virtual-visit__resources-img"
              />
              <p className="virtual-visit__resources-heading">
                Explore Essential Engineering Topics
              </p>
              <p className="virtual-visit__resources-body pb-0.5">
                Inspire students from every background with the infinite
                potential of engineering. Investigate the many ways engineering
                makes our world work.
              </p>
              <Button to="/educators/video-topic-series">Learn More</Button>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Subfooter />
      </Section>
    </Layout>
  );
};

export default RequestVirtualVisit;
