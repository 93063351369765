const activities = {
  'Quenching a Thirst for Innovation (water insecurity)':
    'Quenching a Thirst for Innovation (water insecurity)',
  'A Burning Passion to Give Back (environment & alternative energy sources)':
    'A Burning Passion to Give Back (environment & alternative energy sources)',
  'Growing Into Stronger Communities (social impact of engineering innovations)':
    'Growing Into Stronger Communities (social impact of engineering innovations)',
  'Problem Solvers for Good (real-world stories of STEM-driven solutions)':
    'Problem Solvers for Good (real-world stories of STEM-driven solutions)',
  'Thinking Like an Engineer (engineering design process & power of diverse teams)':
    'Thinking Like an Engineer (engineering design process & power of diverse teams)',
  Other: 'Other',
};

export default activities;
